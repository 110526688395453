"use client";
import {
    Box,
    Button,
    Stack,
    Typography,
} from "@mui/material";
import { useTranslations } from "next-intl";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { useResponsive } from "src/hooks/use-responsive";
import { getCookie } from "cookies-next";
import Image from "next/image";

const HomeBanners = () => {
    const t = useTranslations();
    const mdUp = useResponsive("up", "md");
    const lang = getCookie('shop_lang');
    const heroImagesList = [
        lang === 'pl'
            ? { img: '/assets/hero/xmas-pl.webp', component: null }
            : { img: '/assets/hero/xmas-en.webp', component: null },
    ];

    const heroImages = (
        <Box
            sx={{
                ".swiper-pagination-bullet-active": {
                    width: "16px",
                    height: "16px",
                },
                "--swiper-pagination-color": (theme) => theme.palette.text.primary,
                "--swiper-pagination-bullet-inactive-color": (theme) =>
                    theme.palette.text.disabled,
                "--swiper-pagination-bullet-inactive-opacity": "1",
                "--swiper-pagination-bullet-size": "14px",
                "--swiper-pagination-bullet-horizontal-gap": "5px",
            }}
        >
            <Swiper
                loop={true}
                slidesPerView={1}
                spaceBetween={0}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                autoplay={{
                    disableOnInteraction: false,
                }}
                style={{
                    width: "100%",
                }}
            >
                {heroImagesList.map((_product, index) => (
                    <SwiperSlide key={index}>
                        <Box
                            sx={{
                                position: "relative",
                                width: "100%",
                                maxHeight: "705px",
                                aspectRatio: "16 / 9"
                            }}
                        >
                            <Image
                                src={_product.img}
                                alt="hero"
                                layout="fill"
                                objectFit="cover"
                                objectPosition="top"
                                priority
                            />
                        </Box>
                    </SwiperSlide>
                ))}
            </Swiper>
        </Box>
    );

    return heroImages;
};
export default HomeBanners;

"use client";
import {
  Box,
  Link,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import { useTranslations } from "next-intl";
import { ReactNode } from "react";
import Image from "src/components/image";
import { useResponsive } from "src/hooks/use-responsive";
import { RouterLink } from "src/routes/components";
import { paths } from "src/routes/paths";

const Item = ({
  href,
  image,
  children,
  sx,
  mdUp,
}: {
  href: string;
  image: string;
  children: ReactNode;
  sx: SxProps;
  mdUp: boolean;
}) => (
  <Link
    href={href}
    component={RouterLink}
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      backgroundSize: "contain",
      borderRadius: 1.5,
      py: 2,
      transition: "0.3s",
      flexDirection: "row-reverse",
      "&:hover": {
        opacity: 0.75,
        boxShadow: 0,
        textDecoration: "none",
      },
      ...sx,
    }}
  >
    <Image
      src={image}
      alt="category"
      visibleByDefault={false}
      width={mdUp ? 135 : 115}
      height={mdUp ? 135 : 115}
      sizes="(max-width: 900px) 67px, 87px"
      sx={{
        bgcolor: "background.neutral",
        // bgcolor: theme => alpha(theme.palette.secondary.light, 0.1),
        borderRadius: "100%",
        p: 3,
        boxShadow: 1,
      }}
    />
    {children}
  </Link>
);
const CATEGORIES = [
  {
    title: "_categories.animals",
    image: "/assets/categories/animals.webp",
    href: paths.animals,
  },
  {
    title: "_categories.home",
    image: "/assets/categories/home.webp",
    href: paths.home,
  },
  {
    title: "_categories.garden",
    image: "/assets/categories/garden.webp",
    href: paths.garden,
  },
  {
    title: "_categories.lighting",
    image: "/assets/categories/lighting.webp",
    href: paths.lighting,
  },
  {
    title: "_categories.kitchen",
    image: "/assets/categories/kitchen.webp",
    href: paths.kitchen,
  },
  {
    title: "_categories.gifts",
    image: "/assets/categories/gifts.webp",
    href: paths.gifts,
  },
];

const HomeCategories = () => {
  const t = useTranslations();
  const mdUp = useResponsive("up", "md");
  const xlUp = useResponsive("up", "xl");

  return (
    <Box sx={{ mt: 4, mb: 8, mx: 0, px: xlUp ? 32 : 0 }}>
      <Typography textAlign="center" variant="h4" sx={{ mb: 3 }}>
        {t("categories")}
      </Typography>
      <Stack
        spacing={2}
        display="flex"
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        flexWrap={"wrap"}
        px={{}}
      >
        {CATEGORIES.map((category, index) => (
          <Item
            key={index}
            href={category.href}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
            image={category.image}
            mdUp={mdUp}
          >
            <Typography
              variant="overline"
              sx={{ textDecoration: "none", mt: 2 }}
              color="text.primary"
              fontFamily="text.secondary"
            >
              {t(category.title)}
            </Typography>
          </Item>
        ))}
      </Stack>
    </Box>
  );
};
export default HomeCategories;
